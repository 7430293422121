import React from 'react';
import { NextPage } from 'next';
import Link from 'next/link';
import Image from 'next/legacy/image';

import { BodyText } from '@/components/styled/text';
import classes from '@/components/pages/404/page.module.scss';

import isotipo from '@/public/isotipo.svg';

const NotFound: NextPage = () => {
  return (
    <div className={classes.container}>
      <div className={classes.text}>
        <Link href="/" passHref className={classes.isotipoWrapper}>
          <Image src={isotipo} alt="Isotipo de Anna&Co" />
        </Link>

        <span className={classes.fof}>404</span>

        <div>
          <span className={classes.vaya}>¡Vaya, aquí no hay nada!</span>
          <BodyText>
            Por favor comprueba la dirección de la página y si necesitas ayuda
            para encontrar lo que buscas,{' '}
            <Link href="/contacto">mandame un mensaje</Link>
          </BodyText>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
